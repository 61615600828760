import React from 'react';
import Header from '../../components/Header/Header';
import OTPVerificationSection from '../../components/OTPVerification/OTPVerification'; // New Component
import Footer from '../../components/Footer/Footer';
import './OTPVerificationPage.scss'; // Add appropriate styles in a CSS file or use a CSS framework

const OTPVerificationPage = () => (
  <div className="otp-verification-page">
    <Header />
    <OTPVerificationSection /> {/* Handles OTP form and description */}
    <Footer />
  </div>
);

export default OTPVerificationPage;
