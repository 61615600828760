import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from '../pages/LandingPage/LandingPage';
import PhoneNumberInputPage from '../pages/PhoneNumberInputPage/PhoneNumberInputPage'
import OTPVerificationPage from '../pages/OTPVerificationPage/OTPVerificationPage';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/input-phone" element={<PhoneNumberInputPage />} />
      <Route path="/otp-verification" element={<OTPVerificationPage />} />
    </Routes>
  );
};

export default AppRouter;
