import React from 'react';
import Header from '../../components/Header/Header';
import HeroSection from '../../components/HeroSection/HeroSection';
import FeaturedBankCard from '../../components/FeaturedBankCard/FeaturedBankCard';
import LoanOptions from '../../components/LoanOptions/LoanOptions';
import FeedbackCards from '../../components/FeedbackCards/FeedbackCards';
import Footer from '../../components/Footer/Footer';

const LandingPage = () => (
  <div>
    <Header />
    <HeroSection />
    <FeaturedBankCard />
    <LoanOptions />
    <FeedbackCards />
    <Footer />
  </div>
);

export default LandingPage;
