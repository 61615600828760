import React from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.scss';

const HeroSection = () => (
  <section className="hero">
    <h1>Khám Phá Các Giải Pháp Vay Tối Ưu và Ngân Hàng</h1>
    <p>Được điều chỉnh riêng cho bạn</p>
    <Link to="/input-phone">
      <button className="cta-button">Tiếp Tục</button>
    </Link>
  </section>
);

export default HeroSection;
