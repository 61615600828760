import React, { useEffect, useState } from 'react';
import './FeaturedBankCard.scss';

const FeaturedBankCard = () => {
  const [banks, setBanks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFeaturedBankers = async () => {
      try {
        // Retrieve the base URL from environment variable and append the endpoint
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        console.log("baseUrl = ", baseUrl);
        const response = await fetch(`${baseUrl}/api/v1/borrower/featured_bankers`);
        if (!response.ok) {
          throw new Error('Failed to fetch featured bankers');
        }
        const data = await response.json();
        setBanks(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchFeaturedBankers();
  }, []);

  return (
    <section className="featured-banks">
      <h3>Ngân Hàng Nổi Bật</h3>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div className="banks">
          {banks.map((banker) => (
            <div key={banker.id} className="bank-card">
              <img src={banker.avatar_url} alt={`${banker.full_name}'s avatar`} className="bank-card__avatar" />
              <div className="bank-card__name">{banker.full_name}</div>
              <div className="bank-card__description">{banker.description}</div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default FeaturedBankCard;
