import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendOtp } from '../services/AuthService';

const usePhoneNumberForm = () => { // Lowercase 'use'
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await sendOtp(phoneNumber);
      navigate('/otp-verification'); // Navigate to OTP verification screen
    } catch (error) {
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return {
    phoneNumber,
    handleInputChange,
    handleSubmit,
    error,
    loading,
  };
};

export default usePhoneNumberForm; // Lowercase export
