import React from 'react';
import Header from '../../components/Header/Header';
import PhoneNumberFormSection from '../../components/PhoneNumberFormSection/PhoneNumberFormSection';
import Footer from '../../components/Footer/Footer';
import './PhoneNumberInputPage.scss'; // Add appropriate styles in a CSS file or use a CSS framework

const PhoneNumberInputPage = () => (
  <div className="phone-number-input-page" >
    <Header />
    <PhoneNumberFormSection />
    <Footer />
  </div >
);

export default PhoneNumberInputPage;
