import React, { useState } from 'react';
import './OTPVerification.scss'; // Add appropriate styles in a CSS file or use a CSS framework

const OTPVerification = () => {
  const [otp, setOtp] = useState('');

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('OTP Submitted:', otp);
    // Add your logic for OTP verification here
  };

  return (
    <main className="otp-verification-section">
      <h1>Xác thực OTP</h1>
      <p>
        Vui lòng nhập mã OTP mà bạn đã nhận được trên điện thoại của mình.
      </p>
      <form onSubmit={handleSubmit} className="otp-form">
        <input
          type="text"
          value={otp}
          onChange={handleOtpChange}
          placeholder="Nhập mã OTP"
          className="otp-input"
        />
        <button type="submit" className="otp-button">
          Xác nhận
        </button>
      </form>
      <div className="otp-links">
        <a href="/previous">Quay lại trang trước</a>
        <a href="/">Quay lại trang chủ</a>
      </div>
    </main>
  );
};

export default OTPVerification;
