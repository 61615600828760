import React from 'react';
import './LoanOptions.scss';

const loanOptions = [
  { title: 'Vay Mua Nhà', description: 'Nhận các mức lãi suất tốt nhất cho người mua nhà.' },
  { title: 'Vay Cá Nhân', description: 'Các khoản vay cá nhân được điều chỉnh theo nhu cầu.' },
  { title: 'Vay Mua Xe', description: 'Giải pháp vay mua xe với giá cả phải chăng.' },
  { title: 'Vay Kinh Doanh', description: 'Nâng cao doanh nghiệp với các tùy chọn vay.' }
];

const LoanOptions = () => (
  <section className="loan-options">
    <h3>Các Tùy Chọn Vay</h3>
    <div className="options">
      {loanOptions.map((option, index) => (
        <div key={index} className="option-card">
          <h4>{option.title}</h4>
          <p>{option.description}</p>
        </div>
      ))}
    </div>
  </section>
);

export default LoanOptions;
