import React from 'react';
import './Footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer-section">
      <h4>Về Chúng Tôi</h4>
      <p>Chúng tôi cung cấp các giải pháp ngân hàng và vay cá nhân.</p>
    </div>
    <div className="footer-section">
      <h4>Liên Kết Nhanh</h4>
      <a href="#home">Trang Chủ</a>
      <a href="#services">Dịch Vụ</a>
      <a href="#contact">Liên Hệ</a>
      <a href="#faq">FAQ</a>
    </div>
    <div className="footer-section">
      <h4>Liên Hệ</h4>
      <p>123 Phố Ngân Hàng, Thành Phố Tài Chính</p>
      <p>Email: info@bankingsolutions.com</p>
    </div>
  </footer>
);

export default Footer;
