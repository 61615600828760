import React from 'react';
import usePhoneNumberForm from '../../hooks/usePhoneNumberForm';
import './PhoneNumberFormSection.scss';

const PhoneNumberFormSection = () => {
  const {
    phoneNumber,
    handleInputChange,
    handleSubmit,
    error,
    loading,
  } = usePhoneNumberForm();

  return (
    <section className="phone-number-form-section">
      <div className="form-container">
        <h2 className="form-title">Nhập số điện thoại của bạn</h2>
        <form onSubmit={handleSubmit} className="phone-number-form">
          <input
            type="tel"
            value={phoneNumber}
            onChange={handleInputChange}
            placeholder="Số điện thoại"
            className="input"
            required
          />
          <button type="submit" className="button" disabled={loading}>
            {loading ? 'Đang gửi...' : 'Bắt đầu'}
          </button>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </section>
  );
};

export default PhoneNumberFormSection;
