import React from 'react';
import './Header.scss';

const Header = () => (
  <header className="header">
    <h1 className="header-logo">🏦 Giải Pháp Ngân Hàng</h1>
    <nav className="nav-links">
      <a href="#home">Trang Chủ</a>
      <a href="#about">Về Chúng Tôi</a>
      <a href="#services">Dịch Vụ</a>
      <a href="#contact">Liên Hệ</a>
    </nav>
  </header>
);

export default Header;
