import React from 'react';
import './FeedbackCards.scss';

const feedbacks = [
  { quote: 'Trải nghiệm vay tuyệt vời.', author: 'Sarah' },
  { quote: 'Dịch vụ xuất sắc với lãi suất tuyệt vời.', author: 'David' },
  { quote: 'Chuyên nghiệp và hiệu quả.', author: 'Emma' }
];

const FeedbackCards = () => (
  <section className="feedbacks">
    <h3>Phản Hồi Khách Hàng</h3>
    <div className="feedback-list">
      {feedbacks.map((feedback, index) => (
        <div key={index} className="feedback">
          <p>"{feedback.quote}"</p>
          <span>- {feedback.author}</span>
        </div>
      ))}
    </div>
  </section>
);

export default FeedbackCards;
