import apiClient from '../api/apiClient';

export const sendOtp = async (phoneNumber) => {
  try {
    const response = await apiClient.post('/authentication/send_otp', {
      phone_number: phoneNumber,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending OTP:', error);
    throw error;
  }
};
